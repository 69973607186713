import React from "react";
import "twin.macro";
import { PageProps, graphql } from "gatsby";
import { getImage, IGatsbyImageData } from "gatsby-plugin-image";
import { BlogWrapper } from "../components/pages/blog";
import { IBlogPost } from "../utils/types";
import { SEO } from "../components";

const LegacyBlogPost: React.FC<PageProps> = ({ pageContext, data }) => {
  console.log(pageContext);

  const post = {
    ...pageContext,
    image: { localFile: getImage(data.image) as IGatsbyImageData },
  } as IBlogPost;
  return (
    <>
      <SEO title={post.title} description={post.description} />
      <section>
        <div tw="bg-sonic">
          <div tw="max-w-screen-xl mx-auto px-4 pb-12">
            <BlogWrapper post={post} />
          </div>
        </div>
      </section>
    </>
  );
};

export default LegacyBlogPost;

export const query = graphql`
  query LegacyBlogPostQuery($imageRelativePath: String!) {
    image: file(relativePath: { eq: $imageRelativePath }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 860, quality: 100)
      }
    }
  }
`;
